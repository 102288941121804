<template>
  <div>
    <h2 style="color: black">Organization Merge</h2>
    <p><span>Merging organizations is irreversible and will move any users linked to the organization.</span></p>
  </div>
  <div class="container" style="margin-left: 7%;">
    <div class="row">
      <div class="col-sm-4">

        <ParticipatingCountries
            @countryCode="changeCountry($event)"
            :show-all-select-value="false"
        />
      </div>
      <div class="col-sm-2 mt-4" v-if="showNotFoundMessage" style="text-align: center">
        <p><span style="color: black;">Could not find two or more organizations in the
          {{ selectedCountry === 'US' ? 'state' : 'country' }} selected.</span></p>
      </div>
      <div v-else class="col-sm-2"></div>
      <div v-if="showState" class="col-sm-4">
        <ParticipatingStates
            @selectedState="changeState($event)"
        />
      </div>
    </div>

    <div class="row" v-if="showOrgDropdowns">
      <div class="col-sm-4">
        <label>Keeping Organization</label>
        <select class="form-select" v-model="idOrgOne" @change="changeOrganization($event, idOrgOne)" style="height: 41px;">
          <option :value="-1">Select an Organization</option>
          <option v-for="organization in orgOneFilteredList" :key="organization"
                  :value="organization.idOutStateOrganization">
            {{ organization.organizationName }}
          </option>
        </select>
      </div>
      <div class="col-sm-4 offset-2">
        <label>Removing Organization</label>
        <select class="form-select" v-model="idOrgTwo" @change="changeOrganization($event, idOrgTwo)" style="height: 41px;">
          <option :value="-1">Select an Organization</option>
          <option v-for="organization in orgTwoFilteredList" :key="organization"
                  :value="organization.idOutStateOrganization">
            {{ organization.organizationName }}
          </option>
        </select>
      </div>
    </div>


    <div class="row" v-if="inNebraska">
      <div class="col-sm-4">
        <EsuSelectComposition
            :id-esu="selectedEsu"
            :show-district="false"
            :show-school="false"
            @selectedEsu="changeEsu($event)"/>
      </div>
      <div class="col-sm-2" style="margin-top: 34px">
        <label style="margin-bottom: 0;">Merge Districts?</label>
        <input type="checkbox" v-model="mergeNeDistricts" @click="changeMergingDistricts"/>
      </div>
      <div v-if="!mergeNeDistricts" class="col-sm-4">
        <DistrictSelectComposition
            :id-esu="selectedEsu"
            :id-district="selectedDistrict"
            @selectedDistrict="changeDistrict($event)"
            :show-school="false"
        />
      </div>
    </div>
    <div v-if="inNebraska && selectedDistrict !== -1 && !mergeNeDistricts" class="row">
      <DoubleSchoolFilteredDropdown
          :id-district="selectedDistrict"
          :default-school-two="removingSchool"
          @schoolOne="changeKeepingSchool($event)"
          @schoolTwo="changeRemovingSchool($event)"
      />
    </div>
    <div v-else-if="inNebraska && mergeNeDistricts && selectedEsu !== -1" class="row">
      <DoubleDistrictFilteredDropdown
          :id-esu="selectedEsu"
          :default-district-two="removingDistId"
          @districtOne="changeKeepingDist($event)"
          @districtTwo="changeRemovingDist($event)"/>
    </div>
  </div>
  <div>
    <div v-if="showOutstateOrgData">
      <OutstateOrganizationMerge
          :id-organization-one="idOrgOne"
          :id-organization-two="idOrgTwo"/>
    </div>
    <div v-else-if="removingSchool !== -1 && keepingSchool !== -1 && !mergeNeDistricts">
      <SchoolMerge
          :id-keeping-school="keepingSchool"
          :id-removing-school="removingSchool"/>
    </div>
    <div v-else-if="mergeNeDistricts && keepingDistId !== -1 && removingDistId !== -1">
      <DistrictMerge
          :id-keeping-district="keepingDistId"
          :id-removing-district="removingDistId"
      />
    </div>
  </div>
</template>

<script>
import {computed, onBeforeMount, ref} from "vue";
import OutstateOrganizationMerge from "@/components/Merge/Outstate/OutstateOrganizationMerge.vue";
import ParticipatingCountries from "@/components/Reporting/OrganizationDropdowns/ParticipatingCountries.vue";
import ParticipatingStates from "@/components/Reporting/OrganizationDropdowns/ParticipatingStates.vue";
import {API_URL} from "../../../../Constants";
import axios from "axios";
import EsuSelectComposition from "@/components/LocationSelect/EsuSelectComposition.vue";
import DistrictSelectComposition from "@/components/LocationSelect/DistrictSelectComposition.vue";
import SchoolSelectComposition from "@/components/LocationSelect/SchoolSelectComposition.vue";
import SchoolMerge from "@/views/SuperUserViews/EducationalOrganizationManagement/School/SchoolMerge.vue";
import DoubleSchoolFilteredDropdown from "@/components/LocationSelect/DoubleSchoolFilteredDropdown.vue";
import DoubleDistrictFilteredDropdown from "@/components/LocationSelect/DoubleDistrictFilteredDropdown.vue";
import DistrictMerge from "@/views/SuperUserViews/EducationalOrganizationManagement/District/DistrictMerge.vue";

export default {
  name: "OrgMerge",
  components: {
    DistrictMerge,
    DoubleSchoolFilteredDropdown,
    SchoolMerge,
    // eslint-disable-next-line vue/no-unused-components
    SchoolSelectComposition,
    DistrictSelectComposition,
    EsuSelectComposition, ParticipatingStates, ParticipatingCountries, OutstateOrganizationMerge,
    DoubleDistrictFilteredDropdown,
  },
  props: {
    idRemovingSchool: Number,
    idDistrict: Number,
    idEsu: Number,
    idRemovingDistrict: {
      type: Number,
      default: null
    },
  },
  setup(props) {
    const idOrgOne = ref(-1);
    const idOrgTwo = ref(-1);
    const selectedCountry = ref("-1");
    const selectedState = ref("-1");
    const organizations = ref([]);
    const selectedEsu = ref(-1);
    const selectedDistrict = ref(-1);
    const keepingSchool = ref(-1);
    const removingSchool = ref(-1);
    const keepingDistId = ref(-1);
    const removingDistId = ref(-1);

    const mergeNeDistricts = ref(false);

    onBeforeMount(() => {
      if (props.idRemovingSchool && props.idDistrict && props.idEsu) {
        selectedState.value = "NE";
        selectedCountry.value = "US";
        selectedEsu.value = props.idEsu;
        selectedDistrict.value = props.idDistrict;
        removingSchool.value = props.idRemovingSchool;
      }
      if (props.idRemovingDistrict && props.idEsu) {
        selectedState.value = "NE";
        selectedCountry.value = "US";
        selectedEsu.value = props.idEsu;
        removingDistId.value = parseInt(props.idRemovingDistrict);
        mergeNeDistricts.value = true;
      }
    })

    const showState = computed(() => {
      return selectedCountry.value === 'US';
    })

    const inNebraska = computed(() => {
      return selectedState.value === 'NE';
    });

    const showOrgDropdowns = computed(() => {
      return organizations.value.length > 1;
    })

    const showOutstateOrgData = computed(() => {
      return idOrgOne.value !== -1 && idOrgTwo.value !== -1 && !inNebraska.value;
    })

    const orgOneFilteredList = computed(() => {
      if (idOrgTwo.value !== -1) {
        return organizations.value.filter(organization => organization.idOutStateOrganization !== idOrgTwo.value);
      }
      return organizations.value;
    })

    const orgTwoFilteredList = computed(() => {
      if (idOrgOne.value !== -1) {
        return organizations.value.filter(organization => organization.idOutStateOrganization !== idOrgOne.value);
      }
      return organizations.value;
    })

    const showNotFoundMessage = computed(() => {
      return !inNebraska.value && !showOrgDropdowns.value
          && ((selectedCountry.value !== "-1" && selectedCountry.value !== "US") ||
          (selectedState.value !== "-1"));
    })

    function changeCountry(event) {
      organizations.value = [];
      selectedCountry.value = event;
      changeState("-1");
      if (!inNebraska.value) {
        getOrganizations();
      }
    }

    function changeState(event) {
      organizations.value = [];
      selectedState.value = event;
      if (!inNebraska.value) {
        getOrganizations();
      }
    }

    function changeEsu(event) {
      organizations.value = [];
      selectedEsu.value = event;
    }

    function changeDistrict(event) {
      organizations.value = [];
      selectedDistrict.value = event;
      keepingSchool.value = -1;
      removingSchool.value = -1;
    }

    function changeKeepingSchool(event) {
      keepingSchool.value = event;
    }

    function changeRemovingSchool(event) {
      removingSchool.value = event;
    }

    function changeKeepingDist(event) {
      keepingDistId.value = event;
    }

    function changeRemovingDist(event) {
      removingDistId.value = event;
    }

    async function getOrganizations() {
      let get_uri = API_URL + "/outNeOrganization/";

      if (!showState.value) {
        get_uri += "getByCountry?_countryCode=" + selectedCountry.value;
      } else if (selectedState.value !== '-1' && selectedState.value !== 'NE') {
        get_uri += "getByState?_stateShort=" + selectedState.value;
      }

      await axios.get(get_uri)
          .then((result) => {
            organizations.value = result.data;
          }).finally(() => {
            idOrgOne.value = -1;
            idOrgTwo.value = -1;
          })
    }

    function changeOrganization(event, idValue) {
      idValue = event.target.value;
    }

    function changeMergingDistricts() {
      mergeNeDistricts.value = !mergeNeDistricts.value;
      removingDistId.value = -1;
      keepingDistId.value = -1;
      removingSchool.value = -1;
      keepingSchool.value = -1;
    }

    return {
      idOrgOne,
      idOrgTwo,
      selectedCountry,
      selectedState,
      organizations,
      inNebraska,
      keepingSchool,
      removingSchool,
      selectedEsu,
      selectedDistrict,
      showState,
      showOrgDropdowns,
      showOutstateOrgData,
      orgOneFilteredList,
      orgTwoFilteredList,
      mergeNeDistricts,
      keepingDistId,
      removingDistId,
      showNotFoundMessage,
      changeCountry,
      changeState,
      changeEsu,
      changeDistrict,
      changeKeepingSchool,
      changeRemovingSchool,
      changeOrganization,
      changeKeepingDist,
      changeRemovingDist,
      changeMergingDistricts
    }

  }
}
</script>

<style scoped>

</style>