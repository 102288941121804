<template>
<!--  <div class="row">-->
    <div class="col-sm-4">
      <label>Keeping School</label>
      <select class="form-select" v-model="selectedSchoolIdOne" style="height: 41px;" @change="updateSchoolOne($event)">
        <option :value="-1">Select a School</option>
        <option v-for="school in filteredSchoolsOne" :key="school" :value="school.idSchool">{{ school.schoolName }}
        </option>
      </select>
    </div>
    <div class="col-sm-4 offset-2">
      <label>Removing School</label>
      <select class="form-select" v-model="selectedSchoolIdTwo" style="height: 41px;" @change="updateSchoolTwo($event)" >
        <option :value="-1">Select a School</option>
        <option v-for="school in filteredSchoolsTwo" :key="school" :value="school.idSchool">{{ school.schoolName }}
        </option>
      </select>
    </div>
<!--  </div>-->
</template>

<script>
import {ref, watch, onBeforeMount, computed} from "vue";
import axios from "axios";
import {schoolByDistrict} from "@/Helpers/ApiCalls/SchoolAPICalls";

export default {
  name: "DoubleSchoolFilteredDropdown",
  props: {
    idDistrict: Number,
    defaultSchoolOne: Number,
    defaultSchoolTwo: Number
  },
  setup(props, context) {
    const selectedSchoolIdOne = ref(-1);
    const selectedSchoolIdTwo = ref(-1);
    const schools = ref([]);

    onBeforeMount(() => {
      if (props.idDistrict) {
        getSchools();
      }
    })

    watch(() => props.idDistrict, () => {
      getSchools();
    })

    async function getSchools() {
      schools.value = [];
      selectedSchoolIdTwo.value = -1;
      selectedSchoolIdOne.value = -1;
      await axios.get(schoolByDistrict(), {
        params: {
          districtId: props.idDistrict
        }
      }).then((result) => {
        schools.value = result.data.map(s => ({
          idSchool: s.idSchool,
          schoolName: s.schoolName
        }))
      }).finally(() => {
        if (props.defaultSchoolOne) {
          selectedSchoolIdOne.value = parseInt(props.defaultSchoolOne);
        }

        if (props.defaultSchoolTwo) {
          selectedSchoolIdTwo.value = parseInt(props.defaultSchoolTwo);
        }
      })
    }

    const filteredSchoolsOne = computed(() => {
      if (selectedSchoolIdTwo.value !== -1) {
        return schools.value.filter(s => s.idSchool !== selectedSchoolIdTwo.value);
      }
      return schools.value;
    })

    const filteredSchoolsTwo = computed(() => {
      if (selectedSchoolIdOne.value !== -1) {
        return schools.value.filter(s => s.idSchool !== selectedSchoolIdOne.value);
      }
      return schools.value;
    })

    function updateSchoolOne(event) {
      selectedSchoolIdOne.value = parseInt(event.target.value);
      context.emit("schoolOne", parseInt(event.target.value));
    }

    function updateSchoolTwo(event) {
      selectedSchoolIdTwo.value = parseInt(event.target.value);
      context.emit("schoolTwo", parseInt(event.target.value));
    }

    return {
      selectedSchoolIdOne,
      selectedSchoolIdTwo,
      filteredSchoolsOne,
      filteredSchoolsTwo,
      updateSchoolOne,
      updateSchoolTwo
    }
  }
}
</script>

<style scoped>

</style>