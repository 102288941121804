<template>
  <div class="col-sm-4">
    <label>Keeping District</label>
    <select class="form-select" v-model="selectedDistrictIdOne" style="height: 41px;" @change="updateDistrictOne($event)">
      <option :value="-1">Select a District</option>
      <option v-for="district in filteredDistrictsOne" :key="district" :value="district.idDistrict">
        {{ district.districtName }}
      </option>
    </select>
  </div>
  <div class="col-sm-4 offset-2">
    <label>Removing District</label>
    <select class="form-select" v-model="selectedDistrictIdTwo" style="height: 41px;" @change="updateDistrictTwo($event)">
      <option :value="-1">Select a District</option>
      <option v-for="district in filteredDistrictsTwo" :key="district" :value="district.idDistrict">
        {{ district.districtName }}
      </option>
    </select>
  </div>
</template>

<script>
import {ref, watch, onBeforeMount, computed} from "vue";
import axios from "axios";
import {districtsByEsu, getDistrictsByMultipleEsus} from "@/Helpers/ApiCalls/DistrictAPICalls";

export default {
  name: "DoubleDistrictFilteredDropdown",
  props: {
    idEsu: Number,
    defaultDistrictOne: Number,
    defaultDistrictTwo: Number
  },
  setup(props, context) {
    const selectedDistrictIdOne = ref(-1);
    const selectedDistrictIdTwo = ref(-1);
    const districts = ref([]);

    onBeforeMount(() => {
      getDistricts();
    })

    watch(() => props.idEsu, () => {
      getDistricts();
    })

    async function getDistricts() {
      districts.value = [];
      selectedDistrictIdOne.value = -1;
      selectedDistrictIdTwo.value = -1;
      await axios.get(districtsByEsu(props.idEsu))
          .then((result) => {
            districts.value = result.data.map(d => ({
              idDistrict: d.idDistrict,
              districtName: d.districtName
            }));
          }).finally(() => {
            if (props.defaultDistrictOne) {
              selectedDistrictIdOne.value = parseInt(props.defaultDistrictOne);
            }

            if (props.defaultDistrictTwo) {
              selectedDistrictIdTwo.value = parseInt(props.defaultDistrictTwo);
            }
          })
    }

    const filteredDistrictsOne = computed(() => {
      if (selectedDistrictIdTwo.value !== -1) {
        return districts.value.filter(s => s.idDistrict !== selectedDistrictIdTwo.value);
      }
      return districts.value;
    })

    const filteredDistrictsTwo = computed(() => {
      if (selectedDistrictIdOne.value !== -1) {
        return districts.value.filter(s => s.idDistrict !== selectedDistrictIdOne.value);
      }
      return districts.value;
    })

    function updateDistrictOne(event) {
      selectedDistrictIdOne.value = parseInt(event.target.value);
      context.emit("districtOne", parseInt(event.target.value));
    }

    function updateDistrictTwo(event) {
      selectedDistrictIdTwo.value = parseInt(event.target.value);
      context.emit("districtTwo", parseInt(event.target.value));
    }

    return {
      selectedDistrictIdOne,
      selectedDistrictIdTwo,
      filteredDistrictsOne,
      filteredDistrictsTwo,
      updateDistrictOne,
      updateDistrictTwo
    }
  }
}
</script>

<style scoped>

</style>